import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo';

import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionBreadCrumbs from '../components/sections/SectionBreadCrumbs/SectionBreadCrumbs';
import SectionContent from '../components/sections/SectionContent/SectionContent';
import ContactContainer from '../components/ContactContainer/ContactContainer';

const PageContact = ({
    pageContext,
    location,
    data: {
        contentfulPageCustom: {
            seoTitle,
            seoDescription,
            slug,
            pageTitle,
            sections
        }
    }
}) => {
    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
                lang={pageContext.node_locale}
            />
            <div className='content'>
                {
                    sections?.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentHero":
                                return (
                                    <HeroComponent
                                    data={section}
                                    index={index}
                                    key={index}
                                    first_child={index === 0}
                                    />
                                    )
                                    case "ContentfulSectionBreadCrumbs":
                                        return (
                                            <SectionBreadCrumbs data={section} key={index} title={pageTitle} />
                                            )
                            // case "ContentfulSectionContent":
                            //     return (
                            //         <SectionContent data={section} key={index} />
                            //     )
                        }
                    })
                }
                <ContactContainer />
            </div>
        </div>
    )
}

export const PageContactQuery = graphql`
    query PageContactQuery($id: String!){
        contentfulPageCustom(id: {eq: $id}){
            seoTitle
            slug
            seoDescription {
                seoDescription
            }
            pageTitle
            sections {
                __typename
                ...sectionsBreadcrumbs
                ...componentHero
                ...sectionContent
            }
        }
    }
`

export default PageContact