import React, { useEffect } from 'react'
import { useSiteDictionary } from "../../hooks/index"
import { RichText } from '../Shared/RichText/RichText';
import * as styles from './ContactContainer.module.scss';



const ContactContainer = () => {

    const {
        contactUsContentPart1,
        contactUsContentPart2,
        contactUsButtonText
    } = useSiteDictionary()

    const zendeskLocale = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script")
        script.setAttribute('type', "text/javascript")
        script.innerHTML = `
            zE('webWidget', 'setLocale', 'ja');
        `
        document.body.appendChild(script)

    }

    const zendeskForm = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script")
        script.setAttribute('type', "text/javascript")
        let innerHTML = ''
        script.innerHTML = `
            window.zESettings = {
                webWidget: {
                    chat: { suppress: true },
                    helpCenter: { suppress: true },
                    talk: { suppress: true },
                    answerBot: { suppress: true },
                }
            };
            `
        document.body.appendChild(script)
    }

    const zendesk = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "ze-snippet")
        script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=43d87919-0ce4-412c-9ef6-87d139bd92d8`)
        script.setAttribute('async', 'true')
        script.onload = () => {
            zendeskLocale()
        }
        document.body.appendChild(script)
    }

    useEffect(() => {
        if (!document.getElementById('launcher')) {
            zendeskForm()
            zendesk()
        } else
            zE('webWidget', 'show');

        return () => {
            zE('webWidget', 'hide');
        }
    }, [])

    useEffect(() => {
        if (!document.getElementById('ze-snippet')) {
            zE('webWidget', 'open');
        }
        return () => { zE('webWidget', 'close'); }
    }, [])


    const openPopup = () => {
        zE('webWidget', 'show');
        zE('webWidget', 'open');
    }

    return (contactUsContentPart1 || contactUsContentPart2) && contactUsButtonText ? (
        <section className={`container container-lg-md -${styles.container}`}>
            <div className='recipe-space'>
                {contactUsContentPart1 &&
                    <RichText content={contactUsContentPart1} />
                }
            </div>
            <button className={styles.button} onClick={() => openPopup()}>{contactUsButtonText}</button>
            <div className={`recipe-space ${styles.container2}`}>
                {contactUsContentPart2 &&
                    <RichText content={contactUsContentPart2} />
                }
            </div>
        </section>

    ) : <></>
}

export default ContactContainer